




































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { ExerciseLargeResource, MasterLargeResource, NameValueResource } from '@/store/types'
import { ICureAndTakeHeartForm } from '@/store/types/forms'
import ManagerGroupsModule from '@/store/modules/manager/groups'

@Component({
  components: {
    Autocomplete,
    Dialog,
    ModalWrapper,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CureHeartModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: null })
  private exercise!: ExerciseLargeResource

  @Prop({ default: null })
  private master!: MasterLargeResource

  private get text() {
    if (this.master)
      return `Вы действительно хотите вернуть жизнь мастеру (<span class="modal-emphasis__text">${this.master.user.name}&nbsp;${this.master.user.surname}</span>)? Для этого выберите домашнее задание, за которое будет восстановлена жизнь.`
    return 'Вы действительно хотите вернуть жизнь мастеру?'
  }

  private get masterGroupID() {
    return +this.$route.query.groupID
  }

  private exercises: NameValueResource[] = []

  private form: ICureAndTakeHeartForm = {
    exerciseID: this.exercise ? this.exercise.id : null,
    message: '',
  }

  private mounted() {
    if (!this.exercise && this.master) {
      ManagerGroupsModule.fetchFailedHeart({ masterGroupID: this.masterGroupID, masterID: this.master.user.id })
        .then(response => this.exercises = response)
        .catch(this.notifyError)
    }
  }

  private handleConfirm() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$emit('submit', this.form)
          this.handleClose()
        } else {
          this.notifyError('Проверьте введённые данные.')
        }
      })
  }

  private handleClose() {
    this.$emit('close')
  }
}
